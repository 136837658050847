import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";
import { useQuery } from "../../hook/useQuery";

const FormDistribution = (props) => {
  const [listAccess, setListAccess] = useState(["Iron Park", "Sunrise"]);
  const [accessPrivilege, setAccessPrivilege] = useState([]);
  const [gender, setGender] = useState("");
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("name");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const query = useQuery();

  useEffect(() => {
    setValue("purchaser", props.user?.purchaser_name || props.user?.purchaser);
    setValue("name", props.user?.attendee_name || props.user?.name);
    setValue("email", props.user?.email);
    initGender();
    setAccess();
  }, [props.user]);

  const initGender = () => {
    if (props.user.type.toLowerCase().includes("male")) {
      setGender("male");
      return;
    }

    if (props.user.type.toLowerCase().includes("female")) {
      setGender("female");
      return;
    }
  };

  const setAccess = () => {
    let arr = [];

    //1. Iron Park male -IRON PARK
    //2. Iron Park female - IRON PARK
    // if (props.user.type.toLowerCase().includes("iron")) {
    //   arr.push("Iron Park");
    //   setAccessPrivilege(arr);
    //   return;
    // }

    //3. Soaka male - SUNRISE
    //4. Soaka female - SUNRISE
    // if (props.user.type.toLowerCase().includes("soaka")) {
    //   arr.push("Sunrise");
    //   setAccessPrivilege(arr);
    //   return;
    // }

    //5. Weekend pass male -IRON PARK, SUNRISE
    //6. Weekend pass female -IRON PARK, SUNRISE
    //7. Season pass male -IRON PARK, SUNRISE
    //8. Season pass female -IRON PARK, SUNRISE
    // if (props.user.type.toLowerCase().includes("weekend") || props.user.type.toLowerCase().includes("season")) {
    //   arr.push("Iron Park");
    //   arr.push("Sunrise");
    //   setAccessPrivilege(arr);
    //   return;
    // }

    //9. Comp -IRON PARK, SUNRISE
    // if (props.user.type.toLowerCase().includes("comp")) {
    //   arr.push("Iron Park");
    //   arr.push("Sunrise");
    //   setAccessPrivilege(arr);
    //   return;
    // }

    if (
      props.user.event.toLowerCase().includes("weekend") ||
      props.user.event.toLowerCase().includes("season")
    ) {
      arr.push("Iron Park");
      arr.push("Sunrise");
      setAccessPrivilege(arr);
      return;
    }

    if (
      props.user.event.toLowerCase().includes("soaka") ||
      props.user.event.toLowerCase().includes("sunrise")
    ) {
      arr.push("Sunrise");
      setAccessPrivilege(arr);
      return;
    }

    if (props.user.event.toLowerCase().includes("iron")) {
      arr.push("Iron Park");
      setAccessPrivilege(arr);
      return;
    }
  };

  const onSubmit = (data) => {
    setMsg("");

    if (gender === "") {
      setMsg("Please choose Gender");
      return;
    }

    if (accessPrivilege.length === 0) {
      setMsg("Please choose Access Privilege");
      return;
    }

    if (loading) return;

    setLoading(true);

    const payload = {
      ...props.user,
      attendee_name: data.name,
      purchaser_name: data.purchaser,
      email: data.email,
      gender: gender,
      access_privilege: accessPrivilege.join(","),
    };

    if(query.get("rfid") !== "no"){
      payload.rfid = data.rfid;
    }

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/distribution/add", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setLoading(false);
            props.onSuccess();
          } else {
            setMsg(response?.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Something wrong, please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Something wrong, please try again");
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("rfid", "");
  };

  const validateRFID = (value) => {
    if (value.length === 10) {
      return true;
    } else {
      return false;
    }
  };

  const checkAccess = (value) => {
    let active = accessPrivilege.find((v) => value === v);

    return active !== undefined ? true : false;
  };

  const toogleAcces = (value) => {
    let _arr = [...accessPrivilege];
    let exist = accessPrivilege.find((v) => v === value);
    if (exist !== undefined) {
      _arr = accessPrivilege.filter((v) => v !== value);
    } else {
      _arr.push(value);
    }
    setAccessPrivilege(_arr);
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4"
      >
        <div className="text-center font-bold mb-2">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>

        {true ? (
          <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
            ALLOWED TO COLLECT
          </div>
        ) : (
          <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
            NOT ALLOWED TO COLLECT
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          {/* PURCHASHER */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Purchaser</label>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("purchaser", { required: true })}
            />
          </div>

          {/* ATEENDEE */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Attendee</label>
            </div>

            <input
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("name", { required: true })}
            />
          </div>

          {/* EMAIL */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Email</label>
            </div>
            <input
              type="email"
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("email", { required: true })}
            />
          </div>

          <hr />

          {/* GENDER */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">GENDER</label>
            </div>
            <div className="flex space-x-5">
              <button
                type="button"
                onClick={() => setGender("male")}
                className={`${
                  gender === "male" ? "bg-blue-500" : "bg-gray-500"
                } min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  w-max text-center`}
              >
                MALE
              </button>
              <button
                type="button"
                onClick={() => setGender("female")}
                className={`${
                  gender === "female" ? "bg-blue-500" : "bg-gray-500"
                } min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  w-max text-center`}
              >
                FEMALE
              </button>
            </div>
          </div>

          <hr />

          {/* access privilege */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold uppercase">
                Access Privilege <br /> Event: {props.user.event} <br /> Type:{" "}
                {props.user.type}
              </label>
            </div>

            <div className="flex space-x-5">
              {listAccess?.map((v, key) => {
                return (
                  <button
                    key={key}
                    type="button"
                    onClick={() => toogleAcces(v)}
                    className={`${
                      checkAccess(v) ? "bg-blue-500" : "bg-gray-500"
                    } uppercase min-w-[160px] px-10 py-2 rounded-lg text-white font-bold  w-max text-center`}
                  >
                    {v}
                  </button>
                );
              })}
            </div>
          </div>

          <hr />

          {query.get("rfid") !== "no" && (
            <>
              {" "}
              {/* SCAN RFID */}
              <div className="flex flex-col">
                <div className="flex justify-between mb-1">
                  <label className="text-black w-full font-bold">
                    Scan RFID
                  </label>
                  <button
                    type="button"
                    onClick={() => resetForm()}
                    className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
                  >
                    Clear
                  </button>
                </div>

                <input
                  autoComplete="off"
                  role="presentation"
                  onFocus={() => setFocus("rfid")}
                  className="border-2 px-2 h-[38px] rounded"
                  {...register("rfid", {
                    required: true,
                    validate: validateRFID,
                  })}
                />
              </div>
              {errors.rfid && (
                <span className="text-red-500 text-sm">
                  RFID must consist of 10 characters
                </span>
              )}
            </>
          )}
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>

          <button
            type="submit"
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
          >
            {loading ? "Loading..." : "CONFIRM"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormDistribution;
