import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";

const FormVerification = (props) => {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("name");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const dummy = [];

  const listGender = ["male", "female"];

  const getRandom = (max) => {
    let rnd = Math.floor(Math.random() * max);
    return rnd;
  };

  const listType = [
    {
      ticket_type: "Iron Park - Male",
      access_privilege: "Iron Park",
      gender: "male",
    },
    {
      ticket_type: "Iron Park - Female",
      access_privilege: "Iron Park",
      gender: "female",
    },
    {
      ticket_type: "Sunrise - Male",
      access_privilege: "Sunrise",
      gender: "male",
    },
    {
      ticket_type: "Sunrise - Female",
      access_privilege: "Sunrise",
      gender: "female",
    },
    {
      ticket_type: "Combo - Male",
      access_privilege: "Iron Park, Sunrise",
      gender: "male",
    },
    {
      ticket_type: "Combo - Female",
      access_privilege: "Iron Park, Sunrise",
      gender: "female",
    },
    {
      ticket_type: "Comps",
      access_privilege: "Iron Park, Sunrise",
      gender: listGender[getRandom(listGender.length)],
    },
  ];

  // let i = 0;
  // while (i < 1000) {
  //   let _type = listType[getRandom(listType.length)]

  //   let obj = {
  //     event: "Soaka",
  //     ticket_type: _type.ticket_type,
  //     access_privilege: _type.access_privilege,
  //     gender: _type.gender,
  //     price: "$0 USD",
  //     attendee_name: "User Test " + (i + 1),
  //     purchaser_name: "User Test " + (i + 1),
  //     email: "kulla.tt@gmail.com",
  //     payment_type: "cash",
  //     card: "",
  //     status: true,
  //     message: "Success",
  //     qr_code: i + 1,
  //   };
  //   console.log(obj.gender + " : " + obj.qr_code);
  //   dummy.push(obj);
  //   i++;
  // }

  const onSubmit = (data) => {
    // let user = dummy.find((v) => v.qr_code == data.qr_code);
    // if (user) {
    //   props.onSuccess(user, data.qr_code);
    // } else {
    //   props.onSuccess(null, "");
    // }
    checkQrCode(data);
  };

  const checkQrCode = (data) => {
    if (loading) return;

    setLoading(true);

    const payload = {
      tid: data.qr_code,
      format: "json",
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .get(CONFIG.URL + "/distribution/ticket/" + data.qr_code, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            setLoading(false);
            if (response.data.data.length !== 0) {
              props.onSuccess(response.data?.data[0]);
            } else {
              props.onSuccess({
                ...response.data?.ticket,
                qr_code: data.qr_code,
                ticket_type: response.data?.ticket?.type,
                rfid: "",
              });
            }
          } else {
            props.onSuccess(null);
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setValue("name", "");
    setValue("rfid", "");
    setValue("qr_code", "");
    reset();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4"
      >
        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10">
          {/* SCAN QR CODE */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
                Scan Online QR Code
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
              autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("qr_code")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("qr_code", { required: false })}
            />
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        <button
          type="submit"
          className={`min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
        >
          {loading ? "Loading..." : "Submit"}
        </button>
      </form>
    </>
  );
};

export default FormVerification;
