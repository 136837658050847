import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import useDistribution from "../../hook/useDistribution";

const FormQRResult = (props) => {
  const [loading, setLoading] = useState(false);
  const [userAttend, setUserAttend] = useState(false);
  const [guestAttend, setGuestAttend] = useState(false);

  const onSubmit = () => {
    props.onSuccess();
  };

  const checkCanSubmit = () => {
    if (props.user !== null && props.user?.rfid === "") return true;
    else return false;
  };

  return (
    <>
      <div className="space-y-5 px-5 py-5 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold mb-2">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="text-2xl">DISTRIBUTION</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {props.total}
          </div>
        </div>
        {props.user !== null ? (
          <>
            {props.user?.rfid !== "" ? (
              <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
                ALREADY COLLECTED
              </div>
            ) : (
              <div className="bg-green-500 w-full text-center py-5 text-white font-bold rounded">
                ALLOWED TO COLLECT
              </div>
            )}
          </>
        ) : (
          <div className="bg-red-500 w-full text-center py-5 text-white font-bold rounded">
            NOT ALLOWED TO COLLECT
          </div>
        )}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {props.user?.rfid !== "" && (
                <>
                  <div className="text-center text-2xl font-bold text-black uppercase mb-2">
                    {props.user.attendee_name || props.user.name || ''}
                  </div>
                  <div className="text-center text-2xl font-bold text-black uppercase mb-2">
                   {props.user.purchaser_name || props.user.purchaser || ''}
                  </div>
                </>
              )}
              <div className="text-center text-3xl font-bold text-black uppercase mt-4">
                {props.user.event ||  ''}
              </div>
              <div className="text-center text-3xl font-bold text-black uppercase mt-4">
                {props.user.ticket_type ||  props.user.type || ''}
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>

          {checkCanSubmit() && (
            <button
              type="button"
              onClick={() => onSubmit()}
              className={`mt-10 w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
            >
              {loading ? "Loading..." : "CONFIRM"}
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default FormQRResult;
