import { useCallback, useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import Webcam from "react-webcam";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [imageSrc, setImageSrc] = useState(null);
  const [msg, setMsg] = useState(null);
  const webcamRef = useRef(null);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const videoConstraints = {
    width: { min: 720 },
    height: { min: 480 },
    facingMode: "user",
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  useEffect(() => {
    initAllowed();
  }, [props?.user]);

  const onSubmit = () => {
    if (loading) return;

    if (imageSrc === null) {
      return;
    }

    var file = dataURLtoFile(imageSrc, "photo.png");

    setLoading(true);
    const payload = {
      id_user: props?.user?.id_user,
      id_gate: query.get("id_gate"),
      photo: file,
    };

    var form_data = new FormData();

    for (var key in payload) {
      form_data.append(key, payload[key]);
    }

    try {
      axios
        .post(CONFIG.URL + "/event/checkout", form_data, {
          headers: {
            token: Cookies.get("token"),
          },
        })

        .then(function (response) {
          setLoading(false);
          if (response?.data.status === "SUCCESS") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const initAllowed = () => {
    if (props.user.gate_check === "allow") {
      if (props.user.checkout_time === "0000-00-00 00:00:00") {
        if (props.user.checkin_time === "0000-00-00 00:00:00") {
          setCheckAllow("not-allowed-to-checkout");
        } else if (
          props.user.checkin_time !== "0000-00-00 00:00:00" ||
          props.user.reentry_time !== "0000-00-00 00:00:00"
        ) {
          setCheckAllow("allowed-to-checkout");
        } else if (props.user.reentry_time === "0000-00-00 00:00:00") {
          setCheckAllow("already-checkout");
        }
      } else {
        if (
          Date.parse(props.user.reentry_time) >
          Date.parse(props.user.checkout_time)
        ) {
          setCheckAllow("allowed-to-checkout");
        } else {
          setCheckAllow("already-checkout");
        }
      }
    } else {
      setCheckAllow("wrong-gate");
    }
  };

  const capture = useCallback(() => {
    setImageSrc(webcamRef.current.getScreenshot());
  }, [webcamRef]);

  const retake = () => {
    setImageSrc(null);
  };

  const canSubmit = () => {
    if (imageSrc !== null) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-2 px-3 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all"
      >
        <div className="text-center font-bold">
          <div className="text-base uppercase">CHECKOUT</div>
          <div className="text-xl mt-0 uppercase">{query.get("gate")} Gate</div>
        </div>

        {checkAllow === "allowed-to-checkout" && (
          <div className="bg-green-500 w-full text-center py-2 text-white font-bold rounded">
            ALLOWED TO CHECKOUT
          </div>
        )}

        {checkAllow === "not-allowed-to-checkout" && (
          <div className="bg-red-500 uppercase w-full text-center py-2 text-white font-bold rounded">
            Haven't Check In Yet
          </div>
        )}

        {checkAllow === "already-checkout" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            ALREADY CHECKOUT
          </div>
        )}

        {checkAllow === "wrong-gate" && (
          <div className="bg-red-500 w-full text-center py-2 text-white font-bold rounded">
            WRONG GATE
          </div>
        )}

        <div
          className={`cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 py-1 rounded`}
        >
          {props.user !== null ? (
            <>
              {props.user?.rfid !== "" && (
                <>
                  <div className="text-center text-xl font-bold text-black uppercase">
                    {props.user.attendee_name || props.user.name || "Open User"}{" "}
                    - {props.user.ticket_type || props.user.type || ""}
                  </div>
                </>
              )}

              {checkAllow === "allowed-to-checkout" && (
                <>
                  <div className="relative shadow-lg drop-shadow-lg overflow-hidden my-5">
                    <div
                      className={`${
                        imageSrc !== null ? "invisible" : "visible"
                      } w-[300px]`}
                    >
                      <Webcam
                        audio={false}
                        height={600}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width={800}
                        videoConstraints={videoConstraints}
                        screenshotQuality={1}
                        imageSmoothing={true}
                        mirrored
                      />
                    </div>

                    {imageSrc !== null && (
                      <img
                        alt=""
                        src={imageSrc}
                        className={`${
                          imageSrc !== null ? "block" : "hidden"
                        } w-full h-full object-contain absolute top-0 left-0`}
                      />
                    )}
                  </div>

                  <div className="flex justify-center items-center">
                    {imageSrc === null ? (
                      <button
                        onClick={() => capture()}
                        type="button"
                        className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-blue-500  text-center`}
                      >
                        Capture
                      </button>
                    ) : (
                      <button
                        onClick={() => retake()}
                        type="button"
                        className={`cursor-pointer w-max px-4 py-1 rounded-lg text-white font-bold bg-orange-500  text-center`}
                      >
                        Retake
                      </button>
                    )}
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <button
            type="button"
            onClick={() => props.ok()}
            className={`w-[340px]  px-10 py-2 rounded-lg text-white font-bold bg-red-500  w-full text-center`}
          >
            CANCEL
          </button>
          {checkAllow === "allowed-to-checkout" && (
            <>
              <div className="flex space-x-5">
                {canSubmit() ? (
                  <button
                    type="submit"
                    className={` min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center`}
                  >
                    {loading ? "Loading..." : "Confirm"}
                  </button>
                ) : (
                  <button
                    className={`uppercase cursor-not-allowed min-w-[160px] px-10 py-2 rounded-lg text-white font-bold bg-gray-500  w-full text-center`}
                  >
                    Confirm
                  </button>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </>
  );
};

export default FormRFIDResult;
